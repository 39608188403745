@import "./styles/alpsify-fonts.css";
@import "./styles/alps/css/alps-bluejay.css";
@import "bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.u-background-color--near-white {
  background: #fafafa;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.centerContents {
  text-align: center;
}

/* styles to override bootstrap to make it more "ALPS" like */

.alert {
  border-radius: 0 !important;
}

.no-left-margin {
  margin-left: 0;
  padding-left: 0 !important;
}

.input-group-ctl-parent {
  width: 100%;
  display: flex;
  /* border: 3px solid green; */
}
.input-group-ctl-parent.bordered {
  border: 1px solid #717171;
}
.input-group-ctl-parent.bordered select {
  border: 0px !important;
  -webkit-appearance: button;
  -moz-appearance: button;
  -ms-appearance: button;
  appearance: button;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: none;
}
.input-group-ctl-parent.bordered select:focus,
.input-group-ctl-parent.bordered select:visited,
.input-group-ctl-parent.bordered select:focus-visible,
.input-group-ctl-parent.bordered select:focus-within,
.input-group-ctl-parent.bordered select:active {
  border: 0px !important;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.input-group-ctl-parent .input-group-ctl {
  flex: 1;
}

.input-group-ctl-parent .input-group-ctl-prepend {
  padding: 0.5em;
}
.input-group-ctl select {
  width: 100%;
  /* border: none; */
}

input,
input:focus,
input:hover,
select,
select:focus,
select:hover,
textarea,
textarea:focus,
textarea:hover {
  outline: none;
}

/* tr.selected .o-button--simple{
color: yellow;
} */

.o-button,
.o-button:focus,
.o-button:hover {
  outline: none;
}

.popmenu .u-theme--link-hover--base:hover {
  text-decoration: none;
}

.custom-alps-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  border: none;
}
.custom-alps-check {
  width: fit-content;
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-alps-check:hover {
  color: #2e6de7;
}
.custom-alps-check input ~ .checkmark {
  display: inline-block;
  font-size: 1.2rem;
  color: #ddd;
}
.custom-alps-check:hover input ~ .checkmark {
  font-size: 1.2rem;
  color: #aaa;
}
.custom-alps-check input:checked ~ .checkmark {
  font-size: 1.2rem;
  color: #2e6de7;
}

.no-padding {
  padding: 0px;
}
.data-table-row:hover,
.data-table-row.non-expandable:hover {
  background: #2e6de7;
  color: #fff;
}

.data-table-row.expandable:hover {
  background: #ddd;
  /* color: #000; */
}

.data-table-row.expandable:hover .o-button.o-button--simple path {
  fill: unset;
}
.data-table-row:hover .o-button.o-button--simple path,
.data-table-row:hover svg path,
.data-table-row.non-expandable:hover .o-button.o-button--simple path {
  fill: #fff;
}
.data-table-row td {
  white-space: nowrap;
  font-size: 0.8em;
  padding: 0 0.5rem;
  cursor: pointer;
  border-top: 1.4px solid #eee;
}
.data-table-header-row th {
  white-space: nowrap;
  padding: 0 0.5rem;
}
.data-table-header-row > th:first-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.data-table-row > td:first-child {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 60px;
}

.data-table-row.expandable {
  border-top: 1px solid #000;
  background: #fafafa;
  color: #222;
}
.data-table-row.expandable.identical {
  background: #ffaaaa;
}
.data-table-row.expandable.similar {
  background: #ffeeaa;
}

.data-table-row.expandable.expanded {
  color: #bbb;
}

.data-table-row.expandable td:nth-child(2),
.data-table-row.expandable td:nth-child(3) {
  font-weight: bold;
  color: #2e6de7;
}

.data-table-row.non-expandable {
  background: #fff;
  color: #000;
}

.data-table-row.selected {
  background: #84adfc;
  color: #fff;
}

.data-table-row.selected td {
  border-bottom: 1px solid #eee;
}

.nowrap {
  white-space: nowrap !important;
}
.o-button.alps-bg-scarlett {
  background-color: #d0021b;
  border-color: #d0021b;
}
.whitespace-nowrap {
  white-space: nowrap !important;
}
#global-dialog-title {
  /* background: green; */
  display: flex;
  padding-bottom: 0;
}
#global-dialog-title .title {
  flex: 1;
}
#global-dialog-title .close-button button {
  padding: 0;
}

.chip-list-input input {
  border: none !important;
}
.header-sort-icon {
  position: relative;
  color: #aaa;
}

.header-sort-icon.sort::before {
  content: "";
  -webkit-mask: url(./styles/svg/sort.svg) no-repeat 50% 50%;
  mask: url(./styles/svg/sort.svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  position: absolute;
  top: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  left: -0.7rem;
  background-color: #2e6de7;
}
.header-sort-icon.sort-up::before {
  content: "";
  -webkit-mask: url(./styles/svg/sort-up.svg) no-repeat 50% 50%;
  mask: url(./styles/svg/sort-up.svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  position: absolute;
  top: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  left: -0.7rem;
  background-color: #2e6de7;
}
.header-sort-icon.sort-down::before {
  content: "";
  -webkit-mask: url(./styles/svg/sort-down.svg) no-repeat 50% 50%;
  mask: url(./styles/svg/sort-down.svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  position: absolute;
  top: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  left: -0.7rem;
  background-color: #2e6de7;
}

label.hidden-label {
  width: 1px !important;
  height: 1px !important;
  position: fixed !important;
  top: -1px !important;
  right: -1px !important;
}
