
@-webkit-keyframes scale-in-hor-center {
    0% {
      -webkit-transform: translateX(-100vw);
      transform: translateX(-100vw);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100vw);
      transform: translateX(100vw);
      opacity: 0;
    }
  }
  
  @keyframes scale-in-hor-center {
    0% {
        -webkit-transform: translateX(-100vw);
        transform: translateX(-100vw);
        opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
        -webkit-transform: translateX(100vw);
        transform: translateX(100vw);
        opacity: 0;
    }
  }
  
  .working-indicator {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 0.4rem;
    z-index: 999999;
    background: #2e6de7;
    -webkit-animation: scale-in-hor-center 2s ease-in-out infinite ;
    animation: scale-in-hor-center 2s ease-in-out infinite ;
  }