@-webkit-keyframes fadein {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}


.alps-snackbar {
  display: flex;
  min-width: 240px;
  margin: 0.625rem;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(255, 255, 255, 0.19);
    -webkit-animation: fadein 0.6s both;
            animation: fadein 0.6s both;
}

.alps-snackbar .snackbar-label {
  padding: 0.625rem 0.625rem;
  color: #fff;
}
.alps-snackbar .snackbar-content {
  flex: 1;
  background: #222;
  color: #fff;
  padding: 0.625rem 1.2rem;
  min-width: 200px;
}
.snackbar-label.error {
  background: #cd4900;
}
.snackbar-label.success {
  background: #2b8500;
}
